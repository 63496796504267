<template>
  <link rel="stylesheet" href="https://use.typekit.net/fhn5jaf.css">
  <div id="app" :class="appLoadedClass">
    <div :class="loaded ? 'loading-page inactive' : 'loading-page active'" ref="loadingScreen">
      <h1 id="loading-text">LOADING</h1>
    </div>
    <div id="header">
      <div id="header-img">
        <img class="header-tree" id="header-trees-3" src="@/assets/TREES_3.svg" ref="htree3">
        <img class="header-tree" id="header-trees-2" src="@/assets/TREES_2.svg" ref="htree2">
        <img class="header-tree" id="header-trees-1" src="@/assets/TREES_1.svg" ref="htree1">
        <img id="header-everett" class="center" src="@/assets/EVERETT.svg" ref="hever">
      </div>
    </div>
    <div id="sub-header">
      <!-- <img src="@/assets/WAITING_FOR_THE_SUN.png"> -->
      <!-- <img id="sub-header-logo" class="center" src="@/assets/FORTY_SEVEN.svg"> -->
      <div id="sub-header-text-container" class="center">
        <img src="@/assets/WAITING.svg" ref="waiting" class="blur">
      </div>
    </div>
    <div id="sample-track" class="center">
      <div id="sample-track-buttons">
        <button class="button" id="sample-button-play" @click="start"
          :disabled="tapeSpinning || needsRewind || rewinding"><img src="@/assets/PLAY.svg"></button>
        <button class="button" id="sample-button-stop" @click="stop" :disabled="!tapeSpinning"><img
            src="@/assets/STOP.svg">
        </button>
        <button class="button" id="sample-button-rewind" @click="rewind"
          :disabled="tapePos == 0 || tapeSpinning || rewinding"><img src="@/assets/REWIND.svg"></button>
      </div>
    </div>
    <div id="tape" class="center">
      <audio ref="audioPlayer" :src="sampleTrack.url">
        Your browser does not support the audio element.
      </audio>
      <TapeComponent :current="currentTrackTime" :length="sampleTrack.length" :spinning="tapeSpinning" id="tape"
        ref="tape" @spin="tapePos = $event" @stop="stop(true)" @triggerRewind="needsRewind = true"
        @rewind="needsRewind = false, rewinding = false" :title="sampleTrack.title" />
    </div>
    <div class="mailing-list center">
      <h1>Join Mailing List</h1>
      <form
        action="https://gmail.us13.list-manage.com/subscribe/post?u=9d39b8cf2bf59b6d8e05d9471&amp;id=4be9a66b60&amp;f_id=006879e9f0"
        method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
        class="validate mailing-list-inner" target="_blank">
        <input id="email-input mec-EMAIL" type="email" name="EMAIL" class="required email input" required="" value=""
          placeholder="Email Address">
        <button id="mc-embedded-subscribe" class="button" type="submit" name="subscribe" value="Subscribe">Sign
          Up</button>
        <!-- <div style="position: absolute; left: -5000px;" aria-hidden="true">
              /* real people should not fill this in and expect good things - do not remove this or risk form bot
              signups */
              <input type="text" name="b_9d39b8cf2bf59b6d8e05d9471_4be9a66b60" tabindex="-1" value="">
            </div> -->
      </form>
    </div>
    <div id="album" class="center">
      <div id="track-list">
        <span v-for="track in tracks" :key="track.index">{{ track.title }}</span>
      </div>
      <div id="purchase">
        <button class="button" @click="nav('purchase')" v-if="purchaseUrl != ''">Buy Now</button>
        <button class="button" @click="nav('spotify')" v-if="spUrl != ''">Listen on Spotify</button>
      </div>
    </div>
    <div id="shows">
      <img class="round" src="@/assets/ROUND_1.svg">
      <img class="h-img" id="shows-header" src="@/assets/HEADER_SHOWS.svg">
      <div id="shows-list" class="center">
        <div v-for="show in shows" :key="show.id" :class="show.tickets ? 'show show-hover' : 'show'">
          <img class="show-img" src="@/assets/TICKET.svg">
          <span class="venue">{{ show.venue_name }}</span>
          <span class="location">{{ show.location }}</span>
          <span class="date">{{ showDate(show.date_time.seconds) }}</span>
        </div>
      </div>
    </div>
    <div id="bio-container">
      <div id="bio-img-container">
        <img class="round" src="@/assets/ROUND_2.svg" :style="{ marginTop: '25%' }">
        <img id="bio-img" src="@/assets/BIO_1.png" ref="bio1">
        <img id="bio-img-top" src="@/assets/BIO_2.png" @load="imgLoaded = true" ref="bio2">
      </div>
    </div>
    <div id="about">
      <img class="h-img-raise" src="@/assets/HEADER_ABOUT.svg">
      <div id="about-inner" class="center-wide">
        <p v-html="about"></p>
      </div>
    </div>
    <div id="merch">
      <img class="h-img-raise" src="@/assets/HEADER_MERCH.svg">
      <div id="merch-inner" class="center-wide">
        <h1 v-if="merch.length === 0">COMING SOON</h1>
      </div>
    </div>
    <div id="stories">
      <img class="h-img-raise" src="@/assets/HEADER_STORIES.svg">
      <div id="stories-inner" class="center-wide">
        <img v-if="stories.length > 1" class="arrow right" src="@/assets/ARROW.svg" @click="switchStory(false)">
        <img v-if="stories.length > 1" class="arrow left" src="@/assets/ARROW.svg" @click="switchStory(true)">
        <div class="story" v-if="stories.length > 0">
          <h1>{{ stories[selectedStory].title }}</h1>
          <div class="story-body" v-html="stories[selectedStory].story.replace(/\n/g, '<br>')"></div>
          <p class="italic-light" v-if="stories.length === 1">More coming soon..</p>
          <h1 v-if="stories.length === 0">COMING SOON</h1>
        </div>
      </div>
    </div>
    <div id="footer">
      <img class="h-img-lower" src="@/assets/HEADER_CONTACT.svg">
      <div id="footer-img">
        <img id="footer-tree-3" src="@/assets/B_TREE_3.svg" :style="{ bottom: '0px' }" ref="ftree3">
        <img id="footer-tree-2" src="@/assets/B_TREE_2.svg" :style="{ bottom: '0px' }" ref="ftree2">
        <img id="footer-tree-1" src="@/assets/B_TREE_1.svg" :style="{ bottom: '0px' }" ref="ftree1">
      </div>
      <div id="footer-lower">
        <div id="footer-icons" class="center">
          <img v-if="igUrl != ''" class="icon" src="@/assets/INSTAGRAM.svg" @click="nav('instagram')">
          <img v-if="ytUrl != ''" class="icon" src="@/assets/YOUTUBE.svg" @click="nav('youtube')">
          <img v-if="spUrl != ''" class="icon" src="@/assets/SPOTIFY.svg" @click="nav('spotify')">
          <img v-if="apUrl != ''" class="icon" src="@/assets/APPLE.svg" @click="nav('apple')">
        </div>
        <div id="lower-mailing-list" class="mailing-list center">
          <h1>Join Mailing List</h1>
          <form
            action="https://gmail.us13.list-manage.com/subscribe/post?u=9d39b8cf2bf59b6d8e05d9471&amp;id=4be9a66b60&amp;f_id=006879e9f0"
            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
            class="validate mailing-list-inner" target="_blank">
            <input id="email-input mec-EMAIL" type="email" name="EMAIL" class="required email input" required=""
              value="" placeholder="Email Address">
            <button id="mc-embedded-subscribe" class="button" type="submit" name="subscribe" value="Subscribe">Sign
              Up</button>
            <!-- <div style="position: absolute; left: -5000px;" aria-hidden="true">
              /* real people should not fill this in and expect good things - do not remove this or risk form bot
              signups */
              <input type="text" name="b_9d39b8cf2bf59b6d8e05d9471_4be9a66b60" tabindex="-1" value="">
            </div> -->
          </form>
        </div>
        <div id="booking" class="center-wide">
          <div>
            <h3>Record Label</h3>
            <p>Road Trip Records</p>
            <a href="mailto:roadtriprecordsllc@gmail.com">roadtriprecordsllc@gmail.com</a>
          </div>
          <div>
            <h3>Booking</h3>
            <a href="mailto:everettrogersmusic@gmail.com">everettrogersmusic@gmail.com</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TapeComponent from './components/TapeComponent.vue';
import { db, storage } from './firebase.js';
import { collection, getDocs } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";

/* eslint-disable */
const showsRef = collection(db, "shows");
const merchRef = collection(db, "merch");
const trackListRef = collection(db, "tracklist");
const storiesRef = collection(db, "stories");
const socialsRef = collection(db, "socials");
const aboutRef = collection(db, "about");
const sampleTrackRef = collection(db, "sample_track");

export default {
  name: 'App',
  components: {
    TapeComponent
  },
  data() {
    return {
      scrollTime: false,
      shows: [],
      merch: [],
      /* eslint-disable */
      stories: [],
      scroll: 0,
      selectedStory: 0,
      tapeSpinning: false,
      tapePos: 0,
      needsRewind: false,
      rewinding: false,
      igUrl: '',
      ytUrl: '',
      spUrl: '',
      apUrl: '',
      about: '',
      purchaseUrl: '',
      tracks: [],
      sampleTrack: {},
      loaded: false,
      canScroll: false,
      imgLoaded: false,
      currentTrackTime: 0
    }
  },
  async mounted() {
    setInterval(() => {
      if (this.tapeSpinning) {
        this.currentTrackTime = this.$refs.audioPlayer.currentTime;
      }
    }, 1000);
    new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('unblur');
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.5 }).observe(this.$refs.waiting);
    document.addEventListener('scroll', this.handleScroll);
    const showsSnapshot = await getDocs(showsRef);
    // shows, only render if there is a show
    showsSnapshot.forEach((doc) => {
      this.shows.push(doc.data());
    });
    const merchSnapshot = await getDocs(merchRef);
    merchSnapshot.forEach((doc) => {
      this.merch.push(doc.data());
    });
    const trackListSnapshot = await getDocs(trackListRef);
    // tracklist
    trackListSnapshot.forEach((doc) => {
      this.tracks.push(doc.data());
    });
    this.tracks.sort((a, b) => a.index - b.index);
    const storiesSnapshot = await getDocs(storiesRef);
    // stories, only render if there is a story
    storiesSnapshot.forEach((doc) => {
      this.stories.push(doc.data());
    });
    const socialsSnapshot = await getDocs(socialsRef);
    // social links, only render if there is a URL
    socialsSnapshot.forEach((doc) => {
      if (doc.data().name === 'instagram') {
        this.igUrl = doc.data().url;
      }
      if (doc.data().name === 'youtube') {
        this.ytUrl = doc.data().url;
      }
      if (doc.data().name === 'spotify') {
        this.spUrl = doc.data().url;
      }
      if (doc.data().name === 'apple') {
        this.apUrl = doc.data().url;
      }
      if (doc.data().name === 'purchase') {
        this.purchaseUrl = doc.data().url;
      }
    });
    const aboutSnapshot = await getDocs(aboutRef);
    // should only be one doc here
    aboutSnapshot.forEach((doc) => {
      this.about = doc.data().about.replace(/\n/g, '<br>');
    });
    const sampleTrackSnapshot = await getDocs(sampleTrackRef);
    // should only be one doc here
    sampleTrackSnapshot.forEach((doc) => {
      this.sampleTrack = doc.data();
      getDownloadURL(ref(storage, `${doc.data().file}`)).then((url) => {
        const audioRef = ref(storage, url); // Adjust the path
        getDownloadURL(audioRef).then(dlUrl => {
          this.sampleTrack.url = dlUrl;
        });
      }).catch((error) => {
        console.error(error);
      });
    });
    // give an extra second for images to load hopefully
    setTimeout(() => {
      this.loaded = true;
      setTimeout(() => {
        this.canScroll = true;
        this.$refs.loadingScreen.style.display = 'none';
      }, 2000);
    }, 1000);
  },
  methods: {
    showDate(seconds) {
      //format like "Friday, October 1st at 7:00 PM"
      const date = new Date(seconds * 1000);
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const day = days[date.getDay()];
      const month = months[date.getMonth()];
      const dayNum = date.getDate();
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      return `${day}, ${month} ${this.getOrdinal(dayNum)} at ${formattedHours}:${formattedMinutes} ${ampm}`;
        },
    getOrdinal(n) {
      const s = ['th', 'st', 'nd', 'rd'],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    nav(site) {
      switch (site) {
        case 'instagram':
          window.open(this.igUrl);
          break;
        case 'youtube':
          window.open(this.ytUrl);
          break;
        case 'spotify':
          window.open(this.spUrl);
          break;
        case 'apple':
          window.open(this.apUrl);
          break;
        case 'purchase':
          window.open(this.purchaseUrl);
          break;
      }
    },
    rewind() {
      this.tapeSpinning = false;
      this.$refs.tape.rewind();
      this.rewinding = true;
      this.$refs.audioPlayer.currentTime = 0;
      this.currentTrackTime = 0;
    },
    start() {
      this.tapeSpinning = true;
      this.needsRewind = false;
      this.$refs.tape.start();
      this.$refs.audioPlayer.play();
    },
    stop() {
      this.tapeSpinning = false;
      this.$refs.tape.stop();
      this.$refs.audioPlayer.pause();
    },
    subscribe() {
      // subscribe to mailing list
    },
    handleScroll() {
      this.scroll = window.scrollY;
      this.scrollTime = true;
      if (this.scrollTime) {
        setTimeout(() => {
          this.scrollTime = false;
          if (this.$refs.htree1 !== null && this.$refs.htree2 !== null && this.$refs.htree3 !== null && this.$refs.hever !== null) {
            this.$refs.htree1.style.transform = `translate3d(0, ${this.scroll / 25}px, 0)`;
            this.$refs.htree2.style.transform = `translate3d(0, -${this.scroll / 25}px, 0)`;
            this.$refs.htree3.style.transform = `translate3d(0, ${this.scroll / 20}px, 0)`;
            this.$refs.hever.style.transform = `translate3d(0, ${this.scroll / 25}px, 0)`;
          }
          if (this.$refs.bio1 !== null && this.$refs.bio2 !== null) {
            // translate3d based on scroll position, relative to where the image is in the dom
            this.$refs.bio1.style.transform = `translate3d(0, ${this.scroll / 75}px, 0)`;
            this.$refs.bio2.style.transform = `translate3d(0, ${this.scroll / 75}px, 0)`;
          }
        }, 100);
      }
    },
    switchStory(left) {
      if (left) {
        this.selectedStory = this.selectedStory === 0 ? this.stories.length - 1 : this.selectedStory - 1;
      } else {
        this.selectedStory = this.selectedStory === this.stories.length - 1 ? 0 : this.selectedStory + 1;
      }
    },
    scrollOffset(amount, base, negative = false) {
      return `${base + (this.scroll / 75 * amount * (negative ? -1 : 1))}px`;
    }
  },
  computed: {
    appLoadedClass() {
      return this.loaded ? 'auto-height' : 'page-height';
    },
  }
}
</script>

<style>
@import url('../reset.css');

:root {
  --primary-color: #161616;
  --near-black: #101010;
  --secondary-color: #97a09b;
  --tertiary-color: #808984;
  --dark-color: #2b2d2c;
  --accent-color: white;
  --faded-secondary: #c4c7c573;
  --orange: #725c28;
}

#loading-text {
  font-display: optional;
}

body {
  background-color: var(--secondary-color);
  overscroll-behavior: none;
}

html {
  overscroll-behavior: none;
}

.auto-height {
  height: auto;
}

.page-height {
  height: 100vh;
}

button,
button:hover,
button:active {
  cursor: pointer;
}

.loading-page {
  height: 100vh;
  z-index: 99999;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  transition: background-color 2s ease;
  transition: backdrop-filter 1s ease;
  text-align: center;
  align-content: center;

  &.active {
    background-color: rgba(0, 0, 0, 1);
    backdrop-filter: blur(10px);
    color: white;
  }

  &.inactive {
    background-color: rgba(0, 0, 0, 0);
    color: rgba(255, 255, 255, 0);
    backdrop-filter: blur(0px);
  }
}

.loading-page h1 {
  animation: slow-flash 1s infinite;
  margin-top: 20%;
}

@keyframes slow-flash {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 600px) {
  #sample-button-play {
    margin-left: -5%;
  }

  #shows-header {
    margin-top: 5%;
  }

  .body {
    width: 100%;
  }

  .arrow {
    margin-top: 30%;
  }

  #bio-container {
    height: 400px;
  }

  #bio-img-container {
    height: 375px;
  }

  button,
  input {
    font-family: "poppins", sans-serif;
    font-weight: 600;
    font-size: calc(.2em + 2vw);
    margin-bottom: 5px;
    font-style: semi-bold;
  }

  #header {
    background-color: var(--primary-color);
    color: var(--accent-color);
    display: flex;
    flex-direction: column;
    display: block;
    overflow: hidden;
    height: 400px;
  }
}

@media screen and (min-width: 601px) {
  #sample-button-play {
    margin-left: -5%;
  }

  #shows-header {
    margin-top: calc(5% + .5vw);
  }

  .body {
    width: 60%;
    margin-left: 20%;
  }

  .arrow {
    margin-top: 10%;
  }

  #bio-container {
    height: calc(20vh + 600px);
  }

  #bio-img-container {
    height: 90%;
  }

  button,
  input {
    font-family: "poppins", sans-serif;
    font-weight: 600;
    font-size: calc(.2em + 1vw);
    margin-bottom: 5px;
    font-style: semi-bold;
  }

  #header {
    background-color: var(--primary-color);
    color: var(--accent-color);
    display: flex;
    flex-direction: column;
    display: block;
    overflow: hidden;
    height: 80vh;
  }

  #stories {
    padding-bottom: 10%;
  }
}

@media screen and (min-width: 750px) {
  #sample-button-play {
    margin-left: -4%;
  }

  #shows-header {
    margin-top: calc(5% + 1vw);
  }

  .slight-left {
    margin-left: 10vw;
  }

  .slight-right {
    margin-right: 10vw;
  }
}

@media screen and (min-width: 900px) {
  #sample-button-play {
    margin-left: -3%;
  }

  #shows-header {
    margin-top: calc(5% + 4vw);
  }

  #header {
    height: 110vh;
  }
}

@media screen and (min-width: 1100px) {
  #header {
    height: 120vh;
  }
}

@media screen and (min-width: 1300px) {
  #header {
    height: 140vh;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-color);
  overflow: hidden;
  overscroll-behavior: none;
}


.button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  border-radius: 20px;
  padding: 10px 20px 10px 20px;
  white-space: nowrap;
  text-align: center;
}

.button:hover {
  opacity: .8;
  scale: 1.02;
}

.button:disabled {
  opacity: .3;
}

.button:disabled:hover {
  opacity: .3;
  cursor: not-allowed;
  scale: 1;
}

.button:active {
  scale: 1.04;
}

.input {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 20px;
  padding: 10px 20px 10px 20px;
  white-space: nowrap;
}

.input:hover {
  opacity: .8;
}

.input-inverted {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  border-radius: 20px;
  padding: 10px 20px 10px 20px;
  white-space: nowrap;
}

.input-inverted:hover {
  opacity: .8;
}

.input:focus {
  outline: none;
}

.input-inverted:focus {
  outline: none;
}

#sub-header-text-container {
  animation: fadeInOut 1s;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

#header-img {
  width: 100%;
  position: relative;
  background: url('@/assets/TRAIN.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100%;
  overflow: hidden;
}

#footer {
  background-color: var(--secondary-color);
  color: var(--accent-color);
  display: flex;
  flex-direction: column;
}

#footer-img {
  width: 101%;
  position: relative;
  min-height: 300px;
}

#footer-lower {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--near-black);
}

#footer-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 99;
}

#footer-icons img {
  width: 20%;
  max-width: 100px;
  animation: fadeIn 1s;
  margin: 0 auto;
}

#footer-tree-3 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
}

#footer-tree-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 4;
}

#footer-tree-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
}

#header-everett {
  width: clamp(150px, 50%, 300px);
  position: sticky;
  margin-top: 10%;
  z-index: 12;
}

#header-trees-3 {
  position: absolute;
  left: -2px;
  z-index: 3;
  width: 101%;
  margin: 0 auto;
  bottom: 0px;
}

#header-trees-2 {
  position: absolute;
  left: -2px;
  z-index: 4;
  width: 101%;
  margin: 0 auto;
  bottom: 0px;
}

#header-trees-1 {
  position: absolute;
  left: -2px;
  z-index: 5;
  width: 101%;
  margin: 0 auto;
  bottom: -2px;
}

.header-tree {
  transition-property: transform, visibility, opacity;
  transition-duration: 0s, 0s, 0s;
  transition-timing-function: linear, linear, ease-in-out;
  transition-delay: 0s, 0s, 0s;
  transform: translate3d(0, 0, 0);
}

#header-logo {
  margin-left: 5%;
  margin-top: 5%;
}

#sub-header {
  width: 100%;
}

#sub-header img {
  width: 100%;
}

#sub-header-logo {
  margin-top: 5%;
  height: auto;
}

#sample-track-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  transition: .2s;
}

#sample-track-buttons .button {
  width: 30%;
  max-width: 100px;
  animation: fadeIn 1s;
  padding: 0px;
  border-radius: 3px;
  border: none;
  background: none;
  transition: opacity .2s;
}

#sample-track-buttons .button:disabled {
  opacity: .1;
}

#sample-track-buttons .button:active {
  scale: 1.2;
}

#sample-track-buttons .button:disabled:active {
  scale: 1;
}

#sample-track {
  margin-top: 5%;
}

#tape {
  margin-top: 5%;
}

.mailing-list {
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mailing-list-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.mailing-list-inner input {
  width: 60%;
}

.mailing-list-inner button {
  width: 30%;
}

.mailing-list h1 {
  color: var(--secondary-color);
  font-size: calc(.7em + 2vw);
  margin-left: 2%;
  padding: 0;
  margin-bottom: 0;
}

#lower-mailing-list {}

#album {
  display: flex;
  flex-direction: row;
}

.round {
  width: 102%;
  position: absolute;
  left: -1%;
  top: -1px;
  z-index: 1;
}

#shows {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  min-height: 300px;
  position: relative;
  z-index: 1;
  padding-bottom: 20%;
}

#shows-list {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 5%;
}

.show {
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 7%;
  z-index: 9999;
  scale: 1;
  opacity: .9;
}

.show-hover {
  transition: .05s;
  cursor: pointer;
}

.show-hover:hover {
  scale: 1.01;
  opacity: 1;
}

.show-hover:hover .show-img {
  opacity: 1;
}

.show-img {
  width: 100%;
  position: absolute;
  top: 0;
}

.show .venue {
  font-family: "kiln-serif", sans-serif;
  font-weight: 400;
  font-size: clamp(22px, calc(24px + (36 - 20) * (100vw - 768px)/(1920 - 768)), 40px);
  font-style: normal;
  margin-bottom: 5px;
  margin-left: 10%;
  margin-top: 7%;
  color: var(--primary-color);
}

.show .location {
  font-family: "nexa-rust-sans-black-2", sans-serif;
  font-weight: 900;
  font-size: clamp(10px, calc(14px + (36 - 20) * (100vw - 768px)/(1920 - 768)), 36px);
  font-style: normal;
  margin-bottom: 3px;
  margin-left: 10%;
  color: var(--accent-color);
}

.show .date {
  font-family: "bely", serif;
  font-weight: 400;
  font-size: clamp(12px, calc(14px + (36 - 20) * (100vw - 768px)/(1920 - 768)), 36px);

  font-style: italic;
  margin-left: 10%;
  color: var(--primary-color);
}

.show button {
  position: absolute;
  right: 10%;
  bottom: -10%;
  padding: 5px 20px 5px 20px;
  color: white;
}

#track-list {
  font-family: "poppins", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  color: var(--secondary-color);
  font-size: calc(.3em + 1.5vw);
}

#purchase {
  margin-top: 15%;
  width: 40%;
  margin-left: auto;
}

#purchase button {
  width: 100%;
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

h1 {
  font-family: "kiln-serif", sans-serif;
  font-weight: 400;
  font-size: clamp(28px, calc(28px + (36 - 20) * (100vw - 768px)/(1920 - 768)), 40px);
  margin-bottom: 15px;
  font-style: normal;
}

h2 {
  font-family: "poppins", sans-serif;
  font-weight: 600;
  font-size: clamp(26px, calc(24px + (36 - 20) * (100vw - 768px)/(1920 - 768)), 38px);
  margin-bottom: 5px;
  font-style: normal;
}

p {
  font-family: "poppins", sans-serif;
  font-weight: 400;
}

.h-img {
  z-index: 999;
  position: relative;
  display: block;
  width: clamp(150px, 50%, 400px);
  margin: 0 auto;
  margin-top: 5%;
  transform: translate(0, 0);
}

.h-img-lower {
  z-index: 999;
  position: relative;
  display: block;
  width: clamp(150px, 50%, 400px);
  margin: 0 auto;
  margin-top: 5%;
  transform: translate(0, 50%);
}

.h-img-raise {
  z-index: 999;
  position: relative;
  display: block;
  width: clamp(150px, 50%, 400px);
  margin: 0 auto;
  transform: translate(0, -50%);
}

#about {
  background: var(--dark-color);
  position: relative;
  z-index: 5;
  color: var(--tertiary-color);
}

#bio-container {
  background: var(--near-black);
  position: relative;
  margin-top: -25%;
}

#bio-img-container {
  position: relative;
  background: var(--near-black);
  margin: 0 auto;
}

#bio-img {
  position: absolute;
  top: 0;
  left: 0;
  margin-inline: auto;
  width: fit-content;
  width: clamp(350px, 100%, 750px);
  z-index: 0;
}

#bio-img-top {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;

  width: clamp(350px, 100%, 750px);
}

#about-inner {
  background: var(--dark-color);
  padding-bottom: 20%;
}

/* 
#about-inner p {
  line-height: 1em;
  font-size: calc(.7em + 1vw);
} */

#merch {
  width: 100%;
}

#merch-inner {
  margin-top: 10%;
  padding-bottom: 20%;
  text-align: center;
}

#merch-inner h1 {
  color: var(--secondary-color);
}

#stories {
  background: var(--secondary-color);
  width: 100%;
}

#stories-inner {
  margin-top: 2%;
}

.story {
  width: 80%;
  margin: 0 auto;
}

.arrow {
  width: 30px;
  position: sticky;
  z-index: 99;
}

.arrow:hover,
.icon:hover {
  cursor: pointer;
  scale: 1.1;
  opacity: .8;
}

.arrow:active,
.icon:active {
  scale: 1.2;
}

.right {
  float: right;
}

.left {
  float: left;
  transform: rotate(180deg);
}

span {
  white-space: nowrap;
}

#booking {
  display: flex;
  flex-direction: row;
  background: var(--near-black);
  padding-bottom: 5%;
  padding-top: 5%;
}

#booking div {
  width: 45%;
  scale: .8;
}

#booking h3 {
  font-family: "kiln-serif", sans-serif;
  color: var(--secondary-color);
  font-weight: 400;
  font-size: clamp(24px, calc(24px + (36 - 20) * (100vw - 768px)/(1920 - 768)), 36px);
  margin-left: 13%;
  padding: 0;
  margin-bottom: 3%;
}

#booking p {
  font-family: "poppins", sans-serif;
  color: var(--secondary-color);
  font-weight: 100;
  font-size: clamp(12px, calc(12px + (36 - 20) * (100vw - 768px)/(1920 - 768)), 24px);
  margin-left: 13%;
  padding: 0;
  margin-bottom: 3%;
}

#booking a {
  font-family: "poppins", sans-serif;
  color: var(--secondary-color);
  font-weight: 100;
  font-size: clamp(12px, calc(12px + (36 - 20) * (100vw - 768px)/(1920 - 768)), 24px);
  margin-left: 13%;
  padding: 0;
  margin-bottom: 3%;
}

#booking a:hover {
  opacity: .8;
}

.center {
  display: block;
  width: clamp(350px, 50%, 750px);
  margin: 0 auto;
}

.center-wide {
  display: block;
  width: clamp(350px, 80%, 900px);
  margin: 0 auto;
}

#email-input {
  margin-right: 5%;
}

#email-input:placeholder-shown {
  color: var(--dark-color);
}

#email-input::placeholder {
  color: var(--dark-color);
}

#email-input:focus::placeholder {
  opacity: 0;
}

button {
  text-align: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to {
  opacity: 0
}

.italic-light {
  font-style: italic;
  opacity: .6;
  margin-top: clamp(30px, 10%, 75px);
}

.blur {
  filter: blur(10px);
  transition: 2s;
}

.unblur {
  filter: blur(0px);
  transition: 2s;
}
</style>
